.p-datatable {
  table-layout: fixed;

  .p-sortable-column {
    white-space: nowrap;

    &.p-highlight {
      color: #2B323A;
    }

    .p-sortable-column-icon {
      color: #2B323A !important;
      font-size: 10px;
    }

    .asc:before, .pi-sort-amount-up-alt:before {
      content: "\e9a2";
    }

    .desc:before, .pi-sort-amount-down:before {
      content: "\e9a0";
    }

  }

  th.date-col {
    width: 105px;
  }

  th.nps-col {
    width: 65px;
  }

  tr {
    cursor: pointer;

    &:hover {
      background-color: #e9ecef;
    }

  }

  td.date-col {
    padding-top: 20px !important;
    width: 50px;
    white-space: normal !important;
  }

  td.nps-col {
    vertical-align: top;

    div {
      //margin-top: 4px;
    }
  }

  .service-col {
    white-space: normal !important;
    width: 130px !important;
  }

  .numApp-col {
    width: 80px;
  }

  td.numApp-col {
    padding-top: 0 !important;
  }

  .status-col {
    width: 150px;
    white-space: normal !important;
  }

  .rating-col {
    width: 100px;
  }

  td.rating-col {
    padding-top: 0 !important;
  }

  .auto-col {
    width: 250px;
  }

  .auto-col div:first-child {
    position: relative;
    top: 2px;
  }

  .auto-col div span:first-child {
    display: inline-block;
    max-width: 80%;
    width: auto;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .auto-col div span:last-child {
    width: 20%;
    overflow: hidden;
    display: inline-block;
  }

  .manager-col, .client-col {
    width: 130px;
  }

  .p-datatable-thead {
    tr {
      th {
        padding: 15px 10px;
      }
    }
  }

  .p-datatable-tbody {
    tr {
      color: #2B323A;

      td {
        padding: 15px 10px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.2rem;
      }
    }
  }
}

/*
@media (max-width: 820px) {
  .page {
    &__application-list {}

    .application-list {
      font-size: 12px;
      max-width: 100%;
      width: 100%;
      &__filter{}
      &__wrapper{
        width: 100%;
        padding: 0!important;
      }
    }

  }
}
*/
