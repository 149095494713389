// правый блок профиля
.profile-container {
  position: relative;
  width: 230px;
  margin-top: -70px;
}

.avatar {
  &__black {
    width: 18px !important;
    height: 18px !important;
    background: url("./../../images/header_profile_ico.svg");
  }
}

.ico {

}

.profile {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  font-size: 12px;
  padding: 24px 34px 17px 24px;

  &__user-block {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    position: relative;
  }

  &__client-title {
    font-size: 8px;
    padding-left: 27px;
    padding-bottom: 9px;
    line-height: 10px;
    white-space: nowrap;
  }

  &__ico {
    margin-right: 7px;
  }


  &__user-name {
    white-space: nowrap;
  }

  &__separator {
    display: none;
    height: 1px;
    background-color: #DBDBDB;
    margin-left: -24px;
    margin-right: -34px;
  }

  ul {
    display: none;
    // TODO вынести в общие стили
    padding: 0;
    margin: 0;
    list-style-type: none;

    li {
      padding: 9px 34px 9px 0;
      color: #2F80ED;
      cursor: pointer;

      &:first-child {
        padding-top: 15px;
      }

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  &.active {
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 3px;
    background-color: #fff;

    ul, .profile__separator {
      display: block;
    }
  }

}

@media (max-width: 820px) {
  .profile {
    display: none;
  }
}

@media (max-width: 479px) {
  .profile {
    display: none;
  }
}

