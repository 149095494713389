$font-family: 'Stolzl Book', 'Stolzl', Arial, Helvetica, sans-serif;
$font-family2: 'Stolzl', Arial, Helvetica, sans-serif;
$font-size: 14px;
$font-weight: 400;

$border-radius: 2px;
$color-text: rgba(48, 48, 48, 0.7);
$primary-color: #2B323A;
$border-color: #A6A6A6;

html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-family: $font-family !important;
  font-size: $font-size !important;
  font-weight: $font-weight !important;
  color: $color-text !important;
  height: 100%;

}
.content{
  flex: 1 0 auto;
}
.page {
  h1 {
    font-size: 25px;
    margin-top: 0;
  }
}

.page-menu {
  display: none !important;
}
