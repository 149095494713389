.claims {
  padding: 20px 75px;

  &.mobile {
    padding: 20px;

    .text-field {
      width: 100%;
    }

    &__title {
      h3 {
        color: #373947;
        font-size: 24px !important;
      }
    }

  }

  .p-sidebar-icons {
    padding-bottom: 14px;

    &:hover {
      .close-text {
        color: #008E9A !important;
      }

      .p-sidebar-icon {
        border-color: #008E9A !important;
        color: #008E9A !important;
      }
    }

    .close-text {
      margin-right: 10px;
      color: #7A7A7A;
    }
  }

  .text-field {
    width: 70%;

  }

  .textarea-field {
    .p-inputtextarea {
      line-height: 20px;
    }
  }

  button,
  button:active,
  button:hover,
  button:focus {
    outline: none !important;
    box-shadow: none !important;
    transition: none !important;
  }

  .p-disabled {
    &:hover {
      pointer-events: none;
    }
  }


  .p-sidebar-icon {
    border: 1px solid rgba(0, 0, 0, 0.4);

    .p-sidebar-close-icon {
      font-size: 9px;
    }
  }

  &__title {
    h3 {
      color: #373947;
      font-size: 32px !important;
      line-height: 28px;
    }
  }

  &__body {

  }

}

.claim-status {
  &__submitted{
    color: #FF8A00;
  }
  &__accepted{
    color: #38A749;
  }
  &__declined{
    color: #EB5757;
  }


}

//TODO позже вынести в глобальный класс для всех форм в проекте
.main-form {

  .text-field {
    display: flex;
    flex-direction: column;
    margin: 24px 0;

    label {
      font-size: 12px;
      color: #7A7A7A;
    }

    .p-dropdown {
      width: 100%;
    }


  }

  .textarea-field {
    margin: 24px 0;

    textarea {
      height: 220px !important;
      width: 100%;
    }
  }
}
