$font-family: 'Stolzl Book', 'Stolzl', Arial, Helvetica, sans-serif;
$font-family2: 'Stolzl', Arial, Helvetica, sans-serif;
$font-size: 16px;
$font-weight: 400;

$border-radius: 2px;
$color-text: rgba(48, 48, 48, 0.7);
$primary-color: #2B323A;
$border-color: #A6A6A6;

html, body, #root {
  height: 100%;
  line-height: 24px;
}

.page {
  &__review-list {
    font-family: $font-family !important;
    font-size: $font-size !important;
    font-weight: $font-weight !important;
    color: $color-text !important;
    height: 100%;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0;
    align-self: flex-start;
    background-color: #fff;

    .title {
      max-width: 1690px;
      margin: 0 auto;
      padding: 80px 20px 12px 20px;
    }

    .p-button {
      //padding: 9px 35px 11px 35px;
      padding: 0.4rem 1.5rem;

      .p-button-label {
        font-family: $font-family;
        font-size: 16px !important;
      }

      &.p-button-outlined {
        background: #FFFFFF;
        border: 1px solid #008E9A;
        color: #008E9A !important;
        box-sizing: border-box;
        border-radius: 2px !important;

        &:hover {
          background: #008E9A !important;
          color: #fff !important;
          border: 1px solid #008E9A !important;
        }
      }
    }
  }

  h1 {
    margin: 0;
    padding: 0;
    font-size: 32px;
    color: #303030;
  }

  h3 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: #303030;
  }
}


@media (max-width: 1600px) {
  .page {
    &__review-list {
      .title {
        max-width: 1240px;
        padding: 80px 20px 12px 20px;
      }
    }
  }
}

