@import "src/custom/variables";

.page {
  &__application-detail {
    display: flex;
    flex-grow: 1;

    ::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: $border-radius;
      background-color: rgba(0, 0, 0, .5);
      -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, .5);
    }


    .application-detail {
      max-width: 1690px;
      width: 1690px;
      min-height: 32px;
      margin: 0 auto 80px;
      padding: 80px 20px 12px 20px;
      display: flex;
      justify-content: space-between;

      &__cart {
        width: 100%;
        margin-right: 10px;
      }

      .cart {
        &__wrapper {
          padding-right: 40px;

          .service {
            padding: 15px 0 20px 0;
            font-family: 'Stolzl', Arial, Helvetica, sans-serif;
            color: #373947;
          }

          .descr {
            padding: 15px 0 25px 0;

            .row {
              display: flex;
              flex-direction: row;
              padding: 5px 0;

              .col-title {
                font-weight: bold;
                width: 150px;
                color: #6C8DA3;
              }

              .col-text {
                width: auto;
              }
            }

          }

          .field-box {
            display: flex;
            justify-content: flex-start;

            flex-wrap: wrap;

            .field {
              margin-bottom: 5px;
              margin-right: 30px;
              width: 280px;

              &__label {
                font-size: 12px;
                line-height: 12px;
                color: #6C8DA3;
                padding-bottom: 2px;
                display: inline-block;
              }

              &__text {
                width: 100%;
                height: 42px;
                //min-width: 20px;
                line-height: 2.5rem;
                border: 0.5px solid #2B323A;
                box-sizing: border-box;
                border-radius: $border-radius;
                background-color: #FAFAFA;
                padding: 0 12px;
              }
            }
          }

          h1 {
            span {
              color: #6C8DA3;
              font-weight: 450;
              display: block;
            }
          }
        }
      }

      &__lent {
        width: 100%;
        border-left: 1px solid #DBDBDB;
      }

      .form-btn {

        &__wrapper {
          padding-top: 20px;
          display: flex;
          justify-content: flex-end;

          .p-button {
            padding: 0.4rem 1.5rem
          }
        }
      }

      .lent {
        &__wrapper {
          padding-left: 40px;

          ul {
            list-style: none;
            margin: 0;
            padding: 0 10px 0 0;

            li {
              padding: 8px 0;

              div {
                display: flex;
              }

              .date {
                color: #6C8DA3;
                width: 130px;
                min-width: 130px;
              }

              .text {
                color: #2B323A;
                font-weight: bold;
                display: flex;

                .nps {
                  margin-left: 10px;

                  img {
                    margin-top: 5px;
                  }
                }
              }
            }
          }

          .review {
            display: block;
            border: 1px solid #DBDBDB;
            box-sizing: border-box;
            border-radius: 5px;
            margin-top: 20px;

            &__header {
              display: flex;
              justify-content: space-between;

              .name {
                padding: 20px 0 20px 60px;
                margin-left: 20px;
                margin-right: 10px;
                font-weight: bold;
                background: url('./../../../../images/review_icon1.svg') left 50% no-repeat;
              }

              .rating {
                padding: 20px 20px 20px 0;
                min-width: 85px;
              }
            }

            &__body {
              padding: 0 20px 20px;
            }
          }

          .scroll-panel {
            width: 100%;
            height: 515px;

            .p-scrollpanel-bar {
              background: #6C8DA3;
              border-radius: 6px;
              width: 3px;
            }
          }

        }

      }

      &__bg-transparent {
        background: transparent !important;
        box-shadow: none !important;
      }
    }
  }
}

.application {
  &__loading {
    opacity: 0.3;
  }
}

@media (max-width: 1600px) {
  .page {
    &__application-detail {
      .application-detail {
        max-width: 1240px;
        padding: 35px 20px 12px 20px;
        min-height: 32px;

        .field-box {
          display: flex;

          justify-content: space-between !important;
          flex-wrap: wrap;

          .field {
            margin-bottom: 5px !important;
            margin-right: 0 !important;
            width: 250px !important;
          }
        }

      }
    }
  }
}

@media (max-width: 1200px) {
  .page {
    &__application-detail {
      .application-detail {
        &__cart {
          width: 100%;
          margin-right: 20px;
        }

        .cart {
          &__wrapper {
            padding: 0;
          }
        }

        .field-box {
          .field {
            width: 100% !important;
          }
        }

      }

    }
  }

}

@media (max-width: 900px) {
  .page {
    &__application-detail {
      display: flex;
      flex-grow: 1;

      .application-detail {
        flex-direction: column;
        font-size: 12px;

        h1 {
          line-height: 25px;

          span {
            display: block;
            padding: 5px 0 0 0;

            font-size: 14px;
          }
        }

        &__cart {
          width: 100%;
        }

        .cart {
          &__wrapper {
            padding: 0;
          }
        }

        .field-box {
          .field {
            width: 100% !important;
          }
        }

        &__lent {
          margin-top: 10px;
          width: 100%;
          height: auto;
          border: 0;

        }

        .lent {

          &__wrapper {
            padding: 0;

            ul {
              padding-right: 0;

              li {
                div:not(.review) {
                  display: flex;
                  justify-content: space-between;

                  .date {
                    width: auto;
                  }

                  .text {
                    white-space: nowrap;
                  }
                }

                .review {
                  &__header {
                    line-height: 1rem;

                    .name {
                      padding-left: 40px;
                    }
                  }
                }
              }
            }

            .p-scrollpanel {
              font-size: 12px !important;

              .p-scrollpanel-content {

                div {
                  display: flex;
                  justify-content: space-between;

                  .date {
                    width: auto;
                  }

                  .text {
                    white-space: nowrap;
                  }
                }
              }
            }
          }
        }
      }

    }
  }

}
