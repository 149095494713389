@import "src/custom/variables";

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100% !important;
  height: auto !important;
}

.wrapper {
  align-self: center;
}


.dashboard {
  width: 100%;

  &__loading {
    opacity: 0.2;
  }
}


.p-input-icon-left > .p-inputtext {
  padding-left: 2.5rem !important;
}

/* статус */
.p-tag {
  background-color: transparent;
  text-align: left;
}

.application-tag {
  //padding: 5px 10px 4px 10px !important;
  &.mobile-tag {
    padding: 5px 10px 4px 10px !important;
    top: 0;
  }

  color: #256029;
  font-size: 12px;
  line-height: 17px;
  vertical-align: top;
  padding: 8px 14px;
  display: inline-block;
  border-radius: 0;
}


.status {
  &-inwork {
    background-color: #D4F1D5;
  }
}

/* рейтинг */

.application-rating {
  .p-rating-icon {
    font-size: 16px;
    margin-left: 2px;

    &.pi-star {
      color: #FFC547;
    }

    &.pi-star-o{
      color: #C4C4C4;
      &:before {
        content: "\e937";
      }
    }

  }
  .p-rating-star-on {

  }
}

.application-rating:not(.p-disabled):not(.p-readonly) {
  .pi-star-o:hover {
    color:#C4C4C4;
  }
  .pi-star:hover {
    color:#FFC547;
  }

}

/* пагинация */
.p-paginator {
  padding-top: 50px;
}

.p-paginator-element {
  border: 0 !important;
  color: #90AFC4 !important;
}

.p-paginator-pages {
  .p-paginator-element {
    color: #2B323A !important;

    &.p-highlight {
      background-color: #01B3C2 !important;
      //border-radius: 3px !important;
      color: #FFF !important;
    }
  }
}


/* кнопка действие */
.p-button-action {
  width: 20px !important;
  height: 20px !important;
  background-color: #008E9A;
  line-height: 9px;

  .pi {
    font-size: 8px !important;
  }
}

.p-selectbutton {
  .p-button:not(:last-of-type) {
    &:hover {
      background-color: #01B3C2 !important;
      border-right: 0 !important;
    }
  }
}

.p-button {
  &:focus {
    box-shadow: none;
  }

  &.p-highlight {
    background-color: #008E9A !important;
    border-color: #008E9A !important;
    color: #fff !important;
  }

}

.page {
  &__application-list {
    display: flex;
    flex-grow: 1;

    .title {
      padding: 80px 20px 20px 0;
    }

    .application-list {
      max-width: 1690px;
      min-height: 32px;
      padding: 0 20px;
      margin: 0 auto!important;

      &__filter {
        display: flex;
        width: 100%;
        margin-bottom: 10px;
      }

      &__table {
        width: 100%;
      }

      &__wrapper {
        padding: 20px 0 40px 0;

        h1 {
          span {
            color: #6C8DA3;
            font-weight: 450;
            display: inline-block;
            padding-left: 15px;
          }
        }
      }

      &__search {
        display: flex;
        padding-bottom: 10px;
        .text-field {
          label {
            color: #90AFC4 !important;
            font-size: 12px;
          }
        }
        .status-field {
          width: 250px;
          margin-right: 15px;
          .p-multiselect, .p-dropdown {
            width: 100% !important;
          }
        }

        .word-field {
          width: 470px;
          .p-input-icon-left,
          .p-inputtext {
            height: 42px!important;
            width: 100% !important;
          }
        }
      }

      &__bg-transparent {
        background: transparent !important;
        box-shadow: none !important;
      }

      .filter {
        &__wrapper {
        }
      }

      .filter-content {
        display: flex;
        flex-direction: row;

        &__filter {

          .text-field {
            label {
              color: #90AFC4 !important;
              font-size: 12px;
            }
          }

          .p-selectbutton {
            display: flex;

            .p-button {
              width: 100%;
              background-color: transparent;
              color: #2B323A;
              padding: 7px 14px !important;

              &:hover {
                color: #fff;
              }
            }
          }

          .p-calendar {
            width: 100%;
            margin-right: 10px;

          }

          .date-field {
            display: flex;
            flex-direction: row;

            .text-field {
              margin: 0;

            }

            .text-field:last-child {
              margin-left: 20px;
            }
          }

          .status-field, .managers-field {
            .p-multiselect, .p-dropdown {
              width: 100% !important;
            }
          }
        }

      }

    }

  }
}

.application {
  &__loading {
    opacity: 0.3;
  }
}

.p-datascroller-content {
  background-color: transparent !important;
}

@media (max-width: 1600px) {
  .page {
    &__application-list {
      display: flex;
      flex-grow: 1;

      .application-list {
        max-width: 1240px;
        min-height: 32px;
      }
    }
  }

}
