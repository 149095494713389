@import './Review';

.review-list, .review-charts, .review-filter {
  max-width: 1650px;
  margin: 0 auto;

  &__table {
    width: 100%;
    background-color: #fff;
    margin-bottom: 10px;
    padding: 15px 0;
    //position: relative;

    .loader {
      position: absolute;
      left: 0;
      top: 0;
      background-color: #FFF;
      opacity: 0.5;
      z-index: 2;
      width: 100%;
      height: 100%;
    }

  }

  &__header {
    display: flex;
  }

  &__header-left {
    width: 240px;
    min-width: 240px;
    padding: 5px 30px 20px 4px;
    display: flex;
    align-items: center;

    .count {
      display: inline-block;
      margin-top: 4px;
      color: #008E9A;
      font-size: 14px;
      margin-left: 17px;
    }
  }

  &__item {
    margin-bottom: 20px;
  }

  &__header-right {
    padding: 10px 0 20px 30px;
  }
}

.item {
  border: 1px solid #C1C0C0;
  box-sizing: border-box;
  border-radius: 8px;

  &__wrapper {
    display: flex;

  }

  &__left {
    width: 240px;
    min-width: 240px;
    padding: 43px 30px 15px 40px;
    text-align: center;

    img {
      display: block;
      margin: 0 auto;
    }

    a {
      position: relative;
      display: inline-block;
    }

    .item_subsource-icon {
      position: absolute;
      right: -5px;
      bottom: -5px;
    }
  }

  &__right {
    width: 100%;
    padding: 40px 40px 15px 30px;
  }

  &__body {
    color: rgba(48, 48, 48, 0.7);

    .shadow {
      display: none;
    }

    &.hide {
      max-height: 100px;
      overflow: hidden;
      position: relative;

      .shadow {
        cursor: pointer;
        display: block;
        position: absolute;
        height: 50px;
        width: 100%;
        left: 0;
        top: 55px;
        background: linear-gradient(rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 1));
      }
    }
  }

  &__body-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
  }


  &__author {
    color: #9C9C9C;
    ///padding-bottom: 24px;
    position: relative;
    top: 11px;

    span {
      font-family: $font-family2;
      color: #373947;
      padding-right: 20px;
    }
  }

  &__status {
    display: flex;
    align-items: center;
  }

  &__footer {
    display: flex;
  }

  &__footer-left {
    width: 240px;
    min-width: 240px;
    padding: 15px 30px 40px 40px;
  }

  &__footer-right {
    width: 100%;
    padding: 15px 40px 40px 30px;
    display: flex;
    justify-content: flex-end;

    .p-sidebar{
      &.p-sidebar-right{
        width: 40%;
      }
    }
  }

  &__footer-left, &__footer-right {
    .p-button {
      &.p-disabled {
        opacity: 0.3;

        &:hover {
          background: #FFFFFF !important;
          border: 1px solid #008E9A !important;
          color: #008E9A !important;
        }
      }
    }
  }
}

.show-more-btn {
  a {
    line-height: 20px;
    color: #008E9A;
  }
}

.replay-toggle-btn {
  &__wrapper {
    padding: 20px 0 14px 0;
    display: flex;

    a {
      line-height: 20px;
      color: #008E9A;
    }

    span {
      color: #008E9A;
    }
  }
}

.review-list {
  .rating {
    margin: 28px 4px 0 4px;

    &.mobile {
      margin: 10px 0 0 0;

      .p-rating-icon {
        font-size: 18px;

        &.pi-star {
          &:before {
            width: 18px;
            height: 18px;
          }
        }

        &.pi-star-o {
          &:before {
            width: 18px;
            height: 18px;
          }
        }
      }

    }

    &.no-cursor {
      .p-rating-icon {
        cursor: default;
      }
    }

    .p-rating-icon {

      font-size: 26px;

      &:focus {
        box-shadow: none;
      }

      &.pi-star {
        color: #FFC547;

        &:before {
          content: "\e937";
          display: block;
          width: 26px;
          height: 26px;
        }

        &:hover {
          color: #FFC547;
        }

      }

      &.pi-star-o {
        color: #C4C4C4;

        &:before {
          content: "\e937";
          display: block;
          width: 26px;
          height: 26px;
        }

        &:hover {
          color: #C4C4C4;
        }

      }
    }
  }

  .badge {
    color: #fff;
    //width: 21px;
    min-width: 21px;
    padding: 0 6px;
    height: 21px;
    display: inline-block;
    background-color: #008E9A;
    margin-right: 10px;
    text-align: center;
    border-radius: 21px;
    line-height: 20px;
  }

  .status {
    justify-content: center;
    align-items: center;
    padding: 4px 11px;
    margin-left: 24px;
    white-space: nowrap;

    &.type1 {
      background: #E7F8EE;
      border-radius: 1px;
      color: #38A749;
    }

    &.type2 {
      background: #FFF7E7;
      border-radius: 1px;
      color: #FF8A00;
    }

    &.type3 {
      background: #D4F2F4;
      border-radius: 1px;
      color: #008E9A;
    }

    &.type4 {
      background: #FFECEC;
      border-radius: 1px;
      color: #EB5757;
    }

    &.type5 {
      background: #f0f0f0;
      border-radius: 1px;
      color: #666;
    }

  }
}

.sort {
  &__list {
    display: flex;
  }

  &__item {
    margin-right: 34px;

    &.active {
      color: #008E9A;
    }

    .pi-sort-amount-up-alt, .pi-sort-amount-down {
      top: 1px;
      position: relative;
      font-size: 17px;
    }

  }
}

.p-paginator {
  padding: 10px 0 50px 0;
}

.p-paginator-pages {
  .p-paginator-element.p-highlight {
    background-color: #008E9A;
  }
}

.source {
  position: relative;

  &__title {
    display: flex;
    align-items: center;
    color: #2F80ED;
    cursor: pointer;
    white-space: nowrap;

    span {
      margin: 0 5px 0 5px;

      &.short {
        overflow: hidden;
        /*        width: 200px;*/
        max-width: 200px;
        text-overflow: ellipsis;
      }

    }

    &.no-cursor {
      cursor: default;
    }
  }

  &__body {
    position: absolute;
    left: 0;
    top: 27px;
    z-index: 2;
    width: 320px;
    background: #fff;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 4px;

    ul {
      padding: 16px 24px;
      margin: 0;

      li {
        text-overflow: ellipsis;
        list-style: none;
        white-space: nowrap;
        overflow: hidden;
        margin: 6px 0;
      }
    }
  }
}

.ico {
  min-height: 15px;
  min-width: 15px;
  width: 15px;
  height: 15px;

  &__bag {
    background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.79153C0.447715 1.79153 0 2.23924 0 2.79153V4.8505L7.30495 6.04759C7.41205 6.06514 7.52129 6.06514 7.62838 6.04759L14.9333 4.8505V2.79153C14.9333 2.23924 14.4856 1.79153 13.9333 1.79153H11.2H9.42648H5.50686H4.6201H3.73333H1Z' fill='%232F80ED'/%3E%3Cpath d='M10.1333 4.26338e-05H4.8C4.2109 4.26338e-05 3.73333 0.430473 3.73333 0.961436V1.79153H4.6201H5.50686C5.65944 1.45819 6.02356 1.22358 6.44849 1.22358H8.48485C8.90977 1.22358 9.2739 1.45819 9.42648 1.79153H11.2V0.961435C11.2 0.430473 10.7224 4.26338e-05 10.1333 4.26338e-05Z' fill='%232F80ED'/%3E%3Cpath d='M0 11.8038C0 12.3561 0.447716 12.8038 1 12.8038H13.9333C14.4856 12.8038 14.9333 12.3561 14.9333 11.8038V5.7682L7.62838 6.96529C7.52129 6.98284 7.41205 6.98284 7.30495 6.96529L0 5.7682V11.8038Z' fill='%232F80ED'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  &__user {
    background-image: url('./../../../../images/user_ico.png');
    //background-image: url("data:image/svg+xml,%3Csvg width='15' height='13' viewBox='0 0 15 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.79153C0.447715 1.79153 0 2.23924 0 2.79153V4.8505L7.30495 6.04759C7.41205 6.06514 7.52129 6.06514 7.62838 6.04759L14.9333 4.8505V2.79153C14.9333 2.23924 14.4856 1.79153 13.9333 1.79153H11.2H9.42648H5.50686H4.6201H3.73333H1Z' fill='%232F80ED'/%3E%3Cpath d='M10.1333 4.26338e-05H4.8C4.2109 4.26338e-05 3.73333 0.430473 3.73333 0.961436V1.79153H4.6201H5.50686C5.65944 1.45819 6.02356 1.22358 6.44849 1.22358H8.48485C8.90977 1.22358 9.2739 1.45819 9.42648 1.79153H11.2V0.961435C11.2 0.430473 10.7224 4.26338e-05 10.1333 4.26338e-05Z' fill='%232F80ED'/%3E%3Cpath d='M0 11.8038C0 12.3561 0.447716 12.8038 1 12.8038H13.9333C14.4856 12.8038 14.9333 12.3561 14.9333 11.8038V5.7682L7.62838 6.96529C7.52129 6.98284 7.41205 6.98284 7.30495 6.96529L0 5.7682V11.8038Z' fill='%232F80ED'/%3E%3C/svg%3E");
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

}

@media (max-width: 1600px) {
  .review-list, .review-charts, .review-filter {
    max-width: 1240px;
    padding-left: 20px;
    padding-right: 20px;
  }
}
