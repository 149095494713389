.filter-slider {

  &__title {

  }

  .nav {
    position: relative;
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 1px solid #008E9A;
    border-radius: 30px;
  }

  li {
    margin: 0 10px;
    cursor: pointer;

    &.active {
      a {
        color: #fff;
      }
    }

    a {
      position: relative;
      display: block;
      padding: 6px 10px;
      z-index: 2;
      color: #008E9A;

    }
  }

  .slide1 {
    border-radius: 30px;
    background-color: #008E9A;
    position: absolute;
    display: block;
    transition: all 0.6s cubic-bezier(0.23, 1, 0.32, 1.05);
    bottom: 0;
    height: 100%;
  }
}
