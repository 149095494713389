@import "~react-datepicker/dist/react-datepicker.css";

.calendar {
  cursor: pointer;
  padding: 6px 22px;
  color: #FFFFFF;
  background-color: #008E9A;
  border: 1px solid #15AAB7;
  box-sizing: border-box;
  border-radius: 30px;

  &__wrapper {
    white-space: nowrap;
    display: flex;
    justify-content: center;
  }

  &__left {
    border-radius: 30px 0 0 30px;
    border-right-width: 0;
    padding-right: 5px;
  }

  &__separator {
    color: #FFFFFF;
    background-color: #008E9A;
    border-radius: 0;
    border-top: 1px solid #15AAB7;
    border-bottom: 1px solid #15AAB7;
    border-right-width: 0;
    border-left-width: 0;
    line-height: 33px;
  }

  &__right {
    border-radius: 0 30px 30px 0;
    border-left-width: 0;
    padding-left: 5px;
  }
}

.disabled {
  .calendar {
    background-color: #ccc;
    border: 1px solid #ccc;
    cursor: default;

    &__separator {
      color: #FFFFFF;
      background-color: #ccc;
      border-radius: 0;
      border: 1px solid #ccc;
      border-right-width: 0;
      border-left-width: 0;
      line-height: 33px;
    }
  }
}

.okCalendar {
  border-radius: 15px;
  font-size: 14px;
  -webkit-box-shadow: 0 5px 20px 5px rgba(0,0,0,0.15);
  box-shadow: 0 5px 20px 5px rgba(0,0,0,0.15);
  border: 0;
  font-family: 'Stolzl Book', 'Stolzl', Arial, Helvetica, sans-serif !important;

  .react-datepicker {
    &__triangle {
      display: none;
    }

    &__navigation {
      top: 10px;
    }

    &__navigation-icon::before {
      border-color: #fff;
    }

    &__month-container {
      margin-bottom: 30px;
    }

    &__header {
      border-radius: 15px 15px 0 0;
      padding-top: 0;
      border: 0;
    }

    &__current-month {
      border-radius: 15px 15px 0 0;
      padding: 17px 0 15px 0;
      font-size: 16px;
      background-color: #008E9A;
      line-height: 19px;
      color: #fff;
      font-weight: normal;
      text-transform: uppercase;
    }

    &__day-names {
      background-color: #fff;
    }

    &__day-name {
      color: #6C8DA3;
    }

    &__day, &__day-name {
      width: 35px;
      height: 35px;
      line-height: 37px;
      border-radius: 0;
    }

    &__day--in-selecting-range,
    &__day--selected,
    &__month-text--in-selecting-range,
    &__month-text--selected,
    &__day--in-range {
      color: #fff;
      background-color: #008E9A;
    }

    &__day--in-range {
      color: #fff;
      background-color: #008E9A;

    }
  }

}

.react-datepicker-popper{
  z-index: 5;
}


