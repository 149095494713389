.analytics-footer {
  padding-bottom: 50px;
  margin-top: -25px;

  .analytics-carousel {
    width: 930px;

    ul {
      display: flex;
      padding: 0;
      margin: 0;
      justify-content: center;

      li {
        margin: 0 8px;
        padding: 0;
        list-style: none;
        cursor: pointer;

        img {
          display: block;

          &:hover {
            opacity: 0.5;
          }
        }

      }
    }
  }

}
