@import "src/custom/variables";
@import "src/components/App";

.block4 {
  background: #F0F8FF url("./../../../images/block4_bg.svg") 50% bottom repeat-x;
  .block {

    &__wrapper {
      padding-bottom: 325px;
      max-width: 1200px;
      padding-top: 100px;
      position: relative;


      .img1 {
        position: absolute;
        left: 50%;
        bottom: 22px;
      }

      .img2 {
        position: absolute;
        left: 35px;
        bottom: 25px;
      }

      .title {
        text-align: center;
        width: 850px;
        margin: 0 auto;
      }

      .loading {
        min-height: auto !important;
      }

      .googlePrivacyPolicy {
        font-size: 12px;
        color: #ccc;
        text-align: center;
        padding: 2px 50px 0;
        line-height: 12px;

        a {
          white-space: nowrap;
          color: #ccc;
          text-decoration: underline;
        }
      }

    }

    &__form {
      display: flex;
      justify-content: center;
      min-height: 200px;

      font-family: $font-family !important;
      font-size: $font-size !important;
      font-weight: $font-weight !important;

      .p-inputtext {
        width: 218px;
        height: 40px;
      }
    }

  }

  .contactus {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    padding-bottom: 60px;

    a {
      display: block;
      font-size: 22px;
      color: #2B323A;
      height: 35px;
      line-height: 35px;
      padding-left: 50px;
    }

    &__mailto {
      a {
        background: url("./../../../images/block4_email.svg") 0 0 no-repeat;
        padding-right: 35px;
        line-height: 32px;
      }
    }

    &__tel {
      a {
        background: url("./../../../images/block4_tel.svg") 0 0 no-repeat;
      }

    }
  }

  .form {
    &__field_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
    }

    &__button_wrapper {
      display: flex;
      flex-direction: row;
      justify-content: space-around;

      .submit-btn {
        position: unset;
        height: 40px;
        width: 218px;
      }

    }

    &__wrapper {
      width: 790px;
      padding: 25px 0 20px 0;

      form {
        padding: 0 20px;
      }
    }

    &__title {
      text-align: center;
      padding-bottom: 10px;
      font-size: 20px;
    }

    &__success {
      padding-top: 37px;
      text-align: center;
    }
  }
}

@media (max-width: 1350px) {
  .block4 {
    .block {
      &__wrapper {
        padding-top: 70px;
      }
    }

    .title {
      width: 485px !important;
      font-size: 26px !important;
    }

    .contactus {
      display: flex;
      justify-content: center;
      padding-bottom: 40px;
      margin: 30px auto 0;

      &__mailto {
        padding-bottom: 20px;
      }
    }
  }
}

@media (max-width: 820px) {
  .block4 {
    .form {
      &__wrapper {
        width: 400px;

        form {
          width: 216px;
          display: flex;
          margin: 0 auto;
          flex-direction: column;
        }
      }

      &__success {
        padding-top: 10px;
        width: 300px;
        margin: 0 auto;
      }

      &__title {
        text-align: center;
        width: 200px;
        margin: 0 auto;
      }

      &__bg-white {
        background-color: #FFFFFF;

        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
        border-radius: $border-radius;

      }

    }

    .contactus {
      flex-direction: column;
      width: 250px;
    }

  }
}

@media (max-width: 640px) {
  .block4 {
    .block {
      &__text-content {
        max-width: 100%;
      }
    }

    .title {
      width: 100% !important;
      font-size: 24px !important;
      line-height: 31px;
      text-align: left!important;
    }
    .form {
      &__field_wrapper {
        flex-direction: column;
      }

      &__button_wrapper {
        .submit-btn {
          position: unset;
          height: 40px;
          width: 100%;
        }

      }

      &__wrapper {
        padding: 25px 0 20px 0;

        form {
          width: 100%;
          padding: 0;
        }
      }

      &__title {
        width: 100%;
        margin: 0;
        text-align: left;
        padding-bottom: 10px;
        font-size: 20px;
      }

      &__success {
        padding-top: 37px;
        text-align: center;
      }
    }
    .contactus {

      a {
        font-size: 20px;
      }

      &__mailto {
        a {
          padding-right: 0 !important;
        }
      }
    }


  }
}

@media (max-width: 479px) {
  .block4 .block {
    &__wrapper {
      .img1 {
        z-index: 1;
        width: 150px;
        right: 10px;
        left: unset;
      }

      .img2 {
        left: -13px;
        bottom: 37px;
        width: 200px;
      }
    }

    &__bg-transparent {
      padding-bottom: 300px !important;
    }
  }

}
