@import './Review';

.replay {
  background: #F5F5F5;
  border-radius: 8px;
  margin: 16px 0;

  &.official {
    background: #E6F4FF;
  }

  &__list{
    min-height: 100px;
    position: relative;
  }

  &__loader {
    position: absolute;
    left:0;
    top:0;
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    padding: 24px;
  }

  &__header {
    display: flex;
  }

  &__author {
    color: #9C9C9C;
    padding-bottom: 10px;

    &_official {
      font-family: $font-family !important;
      color: #2F80ED !important;
    }

    span {
      font-family: $font-family2;
      color: #373947;
      padding-right: 20px;
    }
  }

  &__body {
  }

  &__form {
    width: 100%;
    height: 128px;
    border: 1px solid #9C9C9C;
    box-sizing: border-box;
    border-radius: 8px;

    &.active {
      border-color: #008E9A;
    }

    textarea {
      font-size: 16px;
      font-family: $font-family;
      color: $color-text;
      width: 100%;
      height: 98px;
      padding: 15px;
      display: block;
      border: none;
      overflow: auto;
      outline: none;
      background-color: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;

      resize: none; /*remove the resize handle on the bottom right*/
    }

    textarea::placeholder {
      font-family: $font-family;
    }
  }

  &__footer {
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .author {
      font-family: $font-family2;
      color: #373947;
    }
  }

  &__btn_wrapper {
    position: absolute;
    right: -4px;
    bottom: -7px;
    padding: 12px 3px 3px 12px;
    background-color: #fff;
  }

  &__btn {
    &.p-button {
      box-sizing: border-box;
      border-radius: 2px;
      background-color: #DBDBDB !important;
      border: 1px solid #DBDBDB !important;
      color: #FFFFFF;
      padding: 4px 12px !important;

      .p-button-label {
        font-size: 26px !important;
      }

      &.active {
        background-color: #008E9A !important;
        border: 1px solid #008E9A !important;
        color: #FFFFFF;

        &:hover {
          background-color: #FFF !important;
          border: 1px solid #008E9A !important;
          color: #008E9A;
        }

      }

      &:hover {
        background-color: #DBDBDB !important;
        border: 1px solid #DBDBDB !important;
        color: #FFFFFF;
      }
    }
  }
}
