@import 'src/custom/_variables.scss';
@import 'src/components/App';
.page {
  &__index {
    color: #2b323a;
    font-size: 24px;
    line-height: 122%;
    height: auto;

    h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 36px;
      line-height: 45px;
    }

    h5 {
      font-style: normal;
      font-weight: normal;
      font-size: 28px;
      line-height: 122%;
    }
  }

  .form {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.24);
    border-radius: 2px;
  }
}
.promo {
  background: url('./../../../images/promo_block_bg.svg') 50% bottom repeat-x;

  &__wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding-bottom: 325px;
    position: relative;
    overflow: hidden;
  }

  &__img1 {
    position: absolute;
    right: 0;
    bottom: 15px;
  }

  &__img2 {
    display: none;
  }

  &__content {
    padding-top: 60px;
    padding-left: 0;
  }

  &__title {
    max-width: 600px;
  }

  &__subtitle {
    max-width: 600px;
  }
}

.block {
  &__wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }

  &__bg-white {
    background-color: #FFFFFF;
  }

  &__bg-transparent {

  }

  .title {
    font-size: 36px;
  }

  .text {
    font-size: 20px;
  }

  .subtitle {
    font-size: 20px;
  }

  &__list {
    padding-right: 0;
    padding-left: 0;

    li {
      list-style: none;
      background: url('./../../../images/ul_mark.png') no-repeat 0 18px;
      padding: 15px 0 15px 50px;
    }
  }
}

.block1 {
  .block {
    &__wrapper {
      max-width: 1200px;
      padding-top: 50px;
    }

    &__bg-white {
      background-color: #FFFFFF;
      padding: 70px 120px;
      display: flex;
      justify-content: space-between;
    }

    &__picture {
      margin-right: 120px;
    }
  }
}

.block2 {
  background-color: #F0F8FF;
  .block {
    &__wrapper {
      max-width: 1200px;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    &__text-content {
      max-width: 50%;
      padding-top: 10px;
    }

    &__bg-transparent {
      display: flex;
      justify-content: space-between;
    }

    &__picture {
      max-width: 50%;
      margin-right: -57px;
    }
  }
}

.block3 {
  .block {
    &__wrapper {
      max-width: 1200px;
      padding-top: 50px;
    }

    &__bg-white {
      background-color: #FFFFFF;
      padding: 70px 20px 0 20px;
    }

    &__row {
      display: flex;
      justify-content: space-between;

      .col {
        text-align: center;
        width: 33.3%;
        padding: 0 15px 70px 0;


        &__title {
          padding: 10px 0;
          font-weight: 600;
          font-size: 23px;
        }

        &__text {
          font-size: 17px;
          line-height: 22px;
        }

        &__icon {
          display: flex;
          align-items: flex-end;
          justify-content: center;
          padding-bottom: 50px;
        }
      }
    }

    &__picture {
      margin-right: 120px;
    }
  }
}

@media (max-width: 1350px) {
  .page {
    &__index {
      font-size: 22px;
      line-height: 122%;
    }
  }

  .promo {
    &__content {
      padding-left: 40px;
    }
  }

  .block {
    &__wrapper {
      max-width: 1200px;
      margin: 0 auto;
    }

    &__bg-white {
      margin: 0 20px;
    }

    &__bg-transparent {
      margin: 0 40px;
    }
  }

  .block2 {

    .block {
      &__picture {
        margin-right: 0;
      }
    }
  }

  .block3 {

    .block {
      &__row {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-wrap: wrap;

        .col {
          padding: 0 10px 100px 10px;
          width: 50%;

          &__title {
            padding: 10px 0;
            font-weight: 600;
            font-size: 26px;
          }

          &__icon {
            height: 190px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 34px;
          }
        }
      }

      &__picture {
        margin-right: 120px;
      }
    }
  }
}

@media (max-width: 820px) {
  .page {
    &__index {
      font-size: 17px;
      line-height: 1.5rem;

      body {
        font-size: 17px;
      }

      h1 {
        font-size: 24px;
      }

      h5 {
        font-size: 17px;
      }
    }
  }

  .block {
    .title {
      font-size: 24px;
      line-height: 2.2rem;
    }

    .text {
      font-size: 17px;
    }

    .subtitle {
      font-size: 17px;
    }

    &__list {
      li {
        list-style: none;
        background: url('./../../../images/ul_mark_small.png') no-repeat 0 15px;
        padding: 10px 0 10px 26px;
      }
    }

  }

  .block1 {
    .block {
      &__bg-white {
        background-color: #FFFFFF;
        padding: 70px 15px;
        display: flex;
        justify-content: space-between;
      }

      &__picture {
        margin-right: 50px;
      }
    }
  }

  .block2 {
    .block {
      &__text-content {
        max-width: 100%;
      }

      &__bg-transparent {
        display: flex;
        flex-direction: column-reverse;
      }

      &__picture {
        max-width: 100%;
        margin-right: 0;
      }

      &__title{
        padding-bottom: 10px;

      }
    }
  }

  .block3 {
    .block {
      &__wrapper{
        padding-top: 50px!important;
      }
      &__bg-white {
        padding-bottom: 0 !important;
      }

      &__row {
        .col {
          padding: 0 10px 100px 10px;

          &__title {
            padding: 10px 0;
            font-weight: 600;
            font-size: 24px;
            line-height: 2rem;
          }
          &__text{
            font-size: 17px;
          }

          &__icon {
            height: 190px;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            padding-bottom: 34px;
          }
        }
      }

      &__picture {
        margin-right: 120px;
      }
    }
  }
}

@media (max-width: 640px) {

}

@media (max-width: 479px) {

  .block {
    &__wrapper {
      padding: 0 !important;
    }

    &__bg-transparent, &__bg-white {
      display: flex;
      padding: 70px 20px !important;
      margin: 0;
      flex-direction: column;
    }

    &__row {
      display: flex;
      flex-direction: column;
    }

    &__col {
      width: auto !important;
    }

    &__text-content {
      padding: 0 !important;
    }
  }

  .promo {
    &__wrapper {
    }

    &__content {
      padding: 70px 20px 0 !important;
    }

    &__img1 {
      width: 250px;
      left: 20px;
      z-index: 1;
    }

    &__img2 {
      display: block;
      position: absolute;
      width: 200px;
      right: -55px;
      bottom: 40px;
    }
  }

  .block1 .block {
    &__wrapper {
    }

    &__bg-white {
      flex-direction: column-reverse;
      overflow: hidden;
    }

    &__picture {
      margin: 0 0 -340px 0;
      padding-top: 40px;

      display: flex;
      justify-content: center;
    }
  }

  .block2 .block {
    &__picture {
      margin-right: 0;
    }

    &__text-content {
      padding-top: 50px !important;
    }
  }

  .block3 .block {
    &__row {
      .col {
        padding: 0 20px 100px 20px;

        &__title {
          text-align: left;
        }
        &__text {
          text-align: left;
        }
      }
    }
  }

  .page .form {
    margin: 15px 15px 120px;
    width: auto;
  }

  .text-field,
  .p-float-label .p-calendar,
  .p-float-label input {
    width: 100% !important;
  }
}

