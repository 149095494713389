.review-filter {
  padding: 12px 0 28px 0;
}

.filter {

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    color: #303030;
    margin-right: 16px;

    h3 {
      position: relative;
      top: 5px;
    }
  }

  &__clear-btn {
    color: #008E9A;
    padding: 8px 0;
    margin: 0 8px;
    background: #FFFFFF;
    cursor: pointer;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    margin-top: 10px;
    margin-right: 16px;
    display: flex;
    align-items: center;

    .tooltip {
      &__wrapper {
        margin-left: 6px;
      }
    }

  }
}

.warning {
  .filter-button {

    &__title {
      white-space: nowrap;
      color: #EB5757;
      border-color: #EB5757;

      span {
        color: #fff;
        min-width: 21px;
        padding: 0 6px;
        height: 21px;
        display: inline-block;
        background-color: #EB5757;
        text-align: center;
        border-radius: 21px;
        line-height: 20px;
      }

      &:hover, &.selected {
        color: #EB5757;
        border-color: #EB5757;
      }

      &.active {
        color: #FFF;
        border-color: #EB5757;
        background-color: #EB5757;

        span {
          color: #EB5757;
          background-color: #FFF;
        }
      }

    }
  }
}



.filter-button {
  position: relative;

  &__title {
    white-space: nowrap;
    cursor: pointer;
    color: #303030;
    padding: 6px 22px;
    background: #FFFFFF;
    border: 1px solid #373947;
    box-sizing: border-box;
    border-radius: 30px;

    &:hover, &.selected {
      color: #15AAB7;
      border-color: #15AAB7;
    }

    &.active {
      color: #FFFFFF;
      background-color: #008E9A;
      border-color: #15AAB7;
    }

    &.fixed-width {

      &__mobile {
        width: 170px;
      }

      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

  }


  &__dropdown {
    position: absolute;
    left: 0;
    top: 40px;
    background: #FFFFFF;
    box-shadow: -1px 4px 10px rgba(0, 0, 0, 0.13);
    border-radius: 4px;
    z-index: 5;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style: none;
        cursor: pointer;
        min-width: 230px;
        padding: 8px 24px;
        color: #373947;
        position: relative;

        .pi {
          position: absolute;
          font-size: 10px;
          left: 8px;
          top: 15px;
        }

        &.select {
          //          background-color: #F1F1F1;
        }

        &:hover {
          background-color: #F1F1F1;
        }
      }
    }
  }

  &__dropdown-loading {
    position: absolute;
    left: 0;
    top: 0;
    background-color: #FFF;
    width: 100%;
    height: 100%;
    z-index: 2;
    opacity: 0.7;
  }
}

.disabled {
  .filter-button {
    &__title {
      cursor: default;
      background-color: #ccc;
      border-color: #ccc;
      color: #fff;

      &:hover, &.selected, &.active {
        color: #fff !important;
        border-color: #ccc !important;
      }

      &.active {
        color: #fff !important;
        border-color: #ccc !important;
      }

    }
  }
}


