.analytics-table {
  .chart {
    width: 930px;
    border: 1px solid #C1C0C0;
    border-radius: 8px;

    &__header {
      margin-bottom: 50px;
      .header {


        &__title {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin: 24px 0 24px 40px;

          .position {
            &__left {
              display: flex;
            }

            &__right {
              margin-right: 40px;
            }
          }

          .separator {
            color: #9C9C9C;
            font-size: 24px;
            padding: 0 10px;
          }

          .count {
            font-size: 24px;
            color: #373947;
            padding-right: 7px;
          }

          .sub_text {
            padding-top: 3px;
            font-size: 12px;
            color: #7A7A7A;
          }
        }
      }
    }

    &__body {
      height: 300px !important;
      padding-bottom: 30px;
      padding-left: 25px;
      padding-right: 20px;
    }

  }
}

