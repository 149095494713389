.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100% !important;
  height: auto !important;
}


.wrapper {
  align-self: center;
}

.page {
  &__application {
    flex-grow: 1;
    width: 1690px;
    margin: 0 auto 80px;
    padding: 0 20px;
    display: flex;
    flex-direction: column;

    .title {
      padding: 80px 20px 20px 0;
    }

    .form {
      max-width: 600px;
      width: 600px;
      min-height: 32px;
      box-shadow: none;
      margin-top: 10px;

      .form-loading {
        position: absolute;
        left:0;
        top:0;
        background-color: #fff;
        opacity: 0.7;
        width: 100%;
        height: 100%;
        z-index: 2;
      }
      .text-field {
        width: 280px;
        margin: 15px 0;
        .pi-calendar{
          color: #008E9A;
        }
        .p-inputtext, .react-datepicker-wrapper, .p-input-icon-right {
          width: 100%;
        }
      }

      &__bg-transparent {
        background: transparent !important;
        box-shadow: none !important;
      }

      .form-btn {
        &__wrapper {
          margin-top: 25px;
          width: 100%;
          .p-button {
            padding: 0.4rem 1.5rem;
          }

        }
      }
    }

  }
}

.form .successText {
  display: flex;
  align-items: center;
  font-size: 25px;
  height: 135px;
  font-weight: 600;
  text-align: center;
  color: #008E9A;
}

.form .errorSecondText {
  text-align: center;
  font-size: 18px;
  color: #2B323A;
}

.form .errorText {
  color: #C55469;
}

.form .inputTextError {
  color: #C55469;
  font-size: 10px;
  position: absolute;
  right: 0px;
  top: -20px;
}

.successForm {
  margin: 0 auto;

  .successText {
    display: flex;
    font-size: 25px;
    font-weight: bold;
    justify-content: center;
  }

  .btn{
    &__wrapper{
      text-align: center;
      .p-button{
        line-height: 1rem!important;
      }

    }
  }


}

.form form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
}

/*

.submit-btn {
  position: absolute;
  right: -50px;
  bottom: -95px;
}
*/

@media (max-width: 1600px) {
  .page {
    &__application {
      display: flex;
      flex-grow: 1;
      max-width: 1240px;
      min-height: 32px;
    }
  }
}

@media (max-width: 900px) {
  .page {
    &__application {
      max-width: 100%;
      .title{
        padding: 35px 20px 20px 0;
      }
      .form {
        font-size: 12px;
        width: 100%;
        max-width: 100%;
        margin: 15px 0;

        .text-field {
          .p-inputtext, .p-dropdown {
            font-size: 12px!important;
          }
        }

      }
    }
  }
}

/*@media (max-width: 415px) {
  .page {
    &__application {
      h1 {
        line-height: 25px;
        span {
          display: block;
          padding: 5px 0 0 0;
          font-size: 14px;
        }
      }

      .form {
        padding: 20px 20px 40px 20px;
        margin-top:0;
        width: auto;

        button {
          right: 0;
        }

      }
    }
  }
  .blockErrorTest {
    margin: 0 10px;
  }

  .successForm {
    div {
      button {
        right: 0;

      }
    }
  }

}*/
