@import 'src/custom/variables';

.filter-content {
  .dashboard {
    &__sticky {
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
      border-radius: $border-radius;
      position: fixed;
      top: 0;
      left: 0;
      background-color: #fff;
      padding: 10px 15px 0 15px;
      z-index: 5;
      ul{
        padding: 0;
      }
    }
  }

  &__dashboard {
    width: 100%;

    ul {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;

      li {
        display: flex;
        flex-direction: column;
        width: 150px;
        font-size: 12px;
        text-align: center;
        color: #2B323A;
        line-height: 1rem;

        &.item1 > span {
          color:#6AD999;
        }
        &.item2 > span {
          color:#F2C94C;
        }
        &.item3 > span {
          color:#2D9CDB;
        }
        &.item4 > span {
          color:#BB6BD9;
        }
        &.item5 > span {
          color:#56CCF2;
        }

        span {
          display: inline-block;
          font-weight: 400;
          font-size: 36px;
          padding-bottom: 10px;
        }
      }
    }
  }
}
